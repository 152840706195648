import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { removeDuplicatesWithSameId } from '../../utils/removeDuplicatesFromArrayOfString';
const initialState = {
    status: 'idle',
    groups: [],
    entities: [],
    actions: [],
    assignPermission: [],
    groupPermissions: null,
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0
    },
    superAdminPermissions: [],
};
export const getGroups = createAsyncThunk('permissions/getGroups', async () => {
    let data;
    try {
        const response = await axios.get(`/groups`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getPermissions = createAsyncThunk('permissions/getPermissions', async () => {
    let data;
    try {
        const response = await axios.get(`/permissions`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getGroupsById = createAsyncThunk('permissions/getGroupsById', async (id) => {
    let data;
    try {
        const response = await axios.get(`/groups/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createGroups = createAsyncThunk('permissions/createGroups', async ({ name }, thunkApi) => {
    let data;
    try {
        const response = await axios.post(`/groups`, { name });
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(getGroups());
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editGroups = createAsyncThunk('permissions/editGroups', async ({ name, id }, thunkApi) => {
    let data;
    try {
        const response = await axios.put(`/groups/${id}`, { name });
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(getGroups());
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const assignPermissionToGroups = createAsyncThunk('permissions/assignPermissionToGroups', async ({ assignPermission, id, }, thunkApi) => {
    let data;
    try {
        const response = await axios.put(`/groups/${id}/assignPermissions`, assignPermission);
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(getGroupsById(id));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteGroups = createAsyncThunk('permissions/deleteGroups', async (id, thunkApi) => {
    let data;
    try {
        const response = await axios.delete(`/groups/${id}`);
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(getGroups());
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        getAssignPermission: (state, action) => {
            state.assignPermission = removeDuplicatesWithSameId(state.assignPermission
                .filter((item) => !action.payload.permissions?.some((el) => el?.id === item?.id && el?.checkingState === item?.checkingState))
                .concat(action.payload.permissions));
            state.assignPermission = state.assignPermission.map((el) => {
                if (el?.id === action.payload?.id && action.payload?.checkingState !== el?.checkingState) {
                    return {
                        ...el,
                        checkingState: action.payload?.checkingState,
                    };
                }
                return el;
            });
            const idExists = state.assignPermission.some((el) => el?.id === action.payload?.id);
            if (!idExists) {
                state.assignPermission.push({
                    id: action.payload?.id?.toString(),
                    checkingState: action.payload?.checkingState,
                });
            }
        },
        emptyAssignPermission: (state, action) => {
            state.assignPermission = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGroups.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getGroups.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.groups = action.payload;
            state.meta = action.payload.meta;
        })
            .addCase(getGroups.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createGroups.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createGroups.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createGroups.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getGroupsById.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getGroupsById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.groupPermissions = action.payload;
        })
            .addCase(getGroupsById.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getPermissions.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getPermissions.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.superAdminPermissions = action.payload;
        })
            .addCase(getPermissions.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
export const { getAssignPermission, emptyAssignPermission } = slice.actions;
export const reducer = slice.reducer;
export default slice;
